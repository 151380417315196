import { endOfDay, startOfDay } from 'date-fns';
import _ from 'lodash';
import { Observable, tap } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { Params } from '@angular/router';

import { LoadingFeedback } from '@arrivage-components/loading-dialog/loading-feedback.model';
import { ProducersFacade } from '@arrivage-producers/api/producers.facade';
import { ProducerUtils } from '@arrivage-producers/utils/producer.utils';
import { PurchaseOrdersFacade } from '@arrivage-purchase-orders/common/api/purchase-orders.facade';
import { SendPickSplitListDialogComponent } from '@arrivage-scheduled-deliveries/vendor/components/send-pick-split-list-dialog/send-pick-split-list-dialog.component';
import { reportError } from '@arrivage-sentry/report-error';
import { MainPageLoadService } from '@arrivage-services/main-page-load.service';
import { PdfService } from '@arrivage-services/pdf.service';
import { ScheduledDeliveryPdfService } from '@arrivage-services/pdf/scheduled-delivery-pdf/scheduled-delivery-pdf.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { LangUtils } from '@arrivage-util/lang.utils';
import {
  Format,
  Language,
  OrderItem,
  Organization,
  OrganizationSettings,
  OrganizationSummary,
  Producer,
  Product,
  PurchaseOrder,
  WithId,
} from '@arrivage/model/dist/src/model';

import { ScheduledDeliveryApiService } from '../api/scheduled-deliveries.api.service';
import {
  CustomerLine,
  OrderItemsByCategory,
  OrderItemsByProducer,
  PickListInfo,
  ScheduledDeliveriesPdfType,
  ScheduledDelivery,
  ScheduledDeliveryFeedback,
  SendPickSplitListResponseData,
  SendScheduledDeliveriesRecipient,
  WithCustomer,
  WithCustomerLines,
} from '../model/scheduled-deliveries.model';

// Using BREAKLINE constant implies that the message must be displayed as HTML in sendGrid (with triple curly braces)
export const BREAKLINE = '<br>';

export namespace ScheduledDeliveriesUtils {
  export function isOrganizationItselfPresent(
    scheduledDeliveries: ScheduledDelivery[]
  ) {
    return _.some(scheduledDeliveries, (scheduledDelivery) => {
      return _.some(scheduledDelivery.purchaseOrders, (purchaseOrder) => {
        return _.some(
          purchaseOrder.orderItems,
          (orderItem) => !orderItem.product.resellingFor
        );
      });
    });
  }

  export async function getPdfBase64ByProducer(
    pdfService: PdfService,
    scheduledDeliveryPdfService: ScheduledDeliveryPdfService,
    purchaseOrderProducers: (Producer & Partial<WithId>)[],
    scheduledDelivery: ScheduledDelivery[],
    isOrganizationItself: boolean,
    options: {
      commissionPercentage?: number;
      includedDocuments: ScheduledDeliveriesPdfType[];
    } = {
      includedDocuments: Object.values(ScheduledDeliveriesPdfType),
    }
  ) {
    const pdfBase64ByProducer: { [producerName: string]: string } = {};

    const scheduledDeliveriesByProducer = _.reduce(
      purchaseOrderProducers,
      (prev, producer) => {
        const scheduleDeliveryOfProducers: ScheduledDelivery[] =
          scheduledDelivery.map((sd) => {
            return {
              ...sd,
              purchaseOrders: sd.purchaseOrders.map((po) => {
                return {
                  ...po,
                  orderItems: po.orderItems.filter((oi) =>
                    isOrganizationItself
                      ? !oi.product?.producerId
                      : oi.product.producerId === producer.id
                  ),
                };
              }),
            };
          });

        const filteredSdByProducer = scheduleDeliveryOfProducers.map((sd) => {
          return {
            ...sd,
            purchaseOrders: sd.purchaseOrders.filter(
              (po) => po.orderItems.length > 0
            ),
          };
        });

        const purchaseOrdersByProducer = _.flatMap(
          scheduleDeliveryOfProducers,
          (sd) => sd.purchaseOrders
        );

        if (purchaseOrdersByProducer.length > 0) {
          prev[producer.name] = filteredSdByProducer;
        }

        return prev;
      },
      {} as { [producerName: string]: ScheduledDelivery[] }
    );

    const operations = purchaseOrderProducers.map(async (producer) => {
      const pdf =
        await scheduledDeliveryPdfService.generateScheduledDeliveriesPDF(
          scheduledDeliveriesByProducer[producer.name],
          scheduledDelivery[0]?.purchaseOrders[0]?.vendor.name,
          options
        );

      const pdfBase64 = await pdfService.getBase64(pdf);
      pdfBase64ByProducer[producer.name] = pdfBase64;
    });
    await Promise.all(operations);
    return pdfBase64ByProducer;
  }

  /**
   * Functions that takes an array of scheduledDeliveries and check if
   * in these scheduled deliveries there are orders with producers
   * @param scheduledDeliveries
   * @returns boolean
   */
  export function hasMultipleProducers(
    scheduledDeliveries: ScheduledDelivery[]
  ): boolean {
    const allOrderItems: OrderItem[] = _(scheduledDeliveries)
      .flatMap((scheduledDelivery) => scheduledDelivery.purchaseOrders)
      .flatMap((purchaseOrders) => purchaseOrders.orderItems)
      .value();

    return _.some(allOrderItems, (orderItem) => orderItem.product.producerId);
  }

  /**
   * Get producers count for a scheduled delivery
   * @param scheduledDelivery
   * @returns
   */
  export function getProducersCount(
    scheduledDelivery: ScheduledDelivery
  ): number {
    const allOrderItems = _.flatMap(
      scheduledDelivery.purchaseOrders.map(
        (purchaseOrder) => purchaseOrder.orderItems
      )
    );
    return _.uniqBy(
      allOrderItems.filter((orderItem) => orderItem.product.producerId),
      (orderItem) => orderItem.product.producerId
    ).length;
  }

  export function sendPickListAndOrderList(
    scheduledDeliveries: ScheduledDelivery[],
    request: SendScheduledDeliveriesRecipient[],
    snackbarService: SnackbarService,
    isOrganizationOnlyProducer: boolean,
    mainPageLoadService: MainPageLoadService,
    scheduledDeliveryApiService: ScheduledDeliveryApiService
  ) {
    mainPageLoadService.start(LoadingFeedback.ON_SEND_PICK_SPLIT_LIST);
    const action = ScheduledDeliveryFeedback.send_pick_split_list;

    const purchaseOrders = _.flatMap(
      scheduledDeliveries,
      (sd) => sd.purchaseOrders
    );

    scheduledDeliveryApiService
      .sendScheduledDeliveries(request, purchaseOrders)
      .then(() => {
        if (isOrganizationOnlyProducer) {
          snackbarService.showSuccess(action);
        }
      })
      .catch((e) => {
        reportError(e);
        snackbarService.showError(action);
      })
      .finally(() => {
        mainPageLoadService.end();
      });
  }

  export async function sendPickSplitListToProducers(
    isSmallScreen: boolean,
    scheduledDeliveries: ScheduledDelivery[],
    organization: Organization & WithId,
    organizationSettings: OrganizationSettings,
    pdfService: PdfService,
    scheduledDeliveryPdfService: ScheduledDeliveryPdfService,
    dialog: MatDialog,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService,
    scheduledDeliveryApiService: ScheduledDeliveryApiService,
    purchaseOrderProducers?: (Producer & WithId)[]
  ): Promise<Observable<SendPickSplitListResponseData>> {
    const generalMessageToProducerForDelivery =
      organizationSettings?.producers?.generalMessageForProducers;

    const isOrganizationPresent =
      ScheduledDeliveriesUtils.isOrganizationItselfPresent(scheduledDeliveries);

    const dialogRef = SendPickSplitListDialogComponent.openDialog(
      dialog,
      isSmallScreen,
      {
        producers: purchaseOrderProducers,
        generalDeliveryMessageFromSettings: generalMessageToProducerForDelivery,
      }
    );

    return dialogRef.afterClosed().pipe(
      tap(async (response: SendPickSplitListResponseData) => {
        if (response) {
          const commissionPercentage =
            ProducerUtils.getCommissionPercentageValue(
              organizationSettings.producers?.commission
            );

          // Generate desired PDFs for each producer
          const pdfBase64ByProducer =
            await ScheduledDeliveriesUtils.getPdfBase64ByProducer(
              pdfService,
              scheduledDeliveryPdfService,
              purchaseOrderProducers,
              scheduledDeliveries,
              false,
              {
                commissionPercentage,
                includedDocuments: response.includedDocuments,
              }
            );

          let pdfBase64ForOrganizationItself: string;

          if (isOrganizationPresent) {
            const pdfBase64 =
              await ScheduledDeliveriesUtils.getPdfBase64ByProducer(
                pdfService,
                scheduledDeliveryPdfService,
                [{ name: organization.name, email: '' }],
                scheduledDeliveries,
                true,
                {
                  commissionPercentage,
                  includedDocuments: response.includedDocuments,
                }
              );
            pdfBase64ForOrganizationItself = pdfBase64[organization.name];
          }

          // Prepare the request to send the PDFs
          const request: SendScheduledDeliveriesRecipient[] = [];

          response.producers.forEach((producer) => {
            if (producer.isSelected && producer.email) {
              const message = getMessage(
                generalMessageToProducerForDelivery,
                producer?.preparationMessage,
                response?.deliveryMessage
              ).join(BREAKLINE + BREAKLINE);

              request.push({
                email: producer.email,
                pdfBase64: pdfBase64ByProducer[producer.name],
                message: message,
              });
            }
          });

          if (isOrganizationPresent) {
            request.push({
              email: organization.contactInfo.email,
              pdfBase64: pdfBase64ForOrganizationItself,
            });
          }

          ScheduledDeliveriesUtils.sendPickListAndOrderList(
            scheduledDeliveries,
            request,
            snackbarService,
            false,
            mainPageLoadService,
            scheduledDeliveryApiService
          );
        }
      })
    );
  }

  export function shouldAddProducer(
    oi: OrderItem,
    purchaseOrderProducers: (Producer & WithId)[]
  ) {
    return !purchaseOrderProducers.find((orderProducer) => {
      return orderProducer?.id === oi.product?.producerId;
    });
  }

  export async function updateProducer(
    allProducers: (Producer & WithId)[],
    oi: OrderItem,
    producersFacade: ProducersFacade
  ): Promise<Producer & WithId> {
    let foundProducer = allProducers.find((producer) => {
      return producer.name === oi.product?.resellingFor;
    });

    if (!foundProducer) {
      const createdProducer: Producer = {
        name: oi.product.resellingFor,
        email: null,
      };

      if (oi.product?.producerId) {
        foundProducer = {
          ...createdProducer,
          id: oi.product.producerId,
        };
      } else {
        const newProducerId =
          await producersFacade.createProducer(createdProducer);

        foundProducer = {
          ...createdProducer,
          id: newProducerId,
        };
      }
    }

    return foundProducer;
  }

  export async function updatePickListInfo(
    scheduledDelivery: ScheduledDelivery[],
    allProducers: (Producer & WithId)[],
    purchaseOrderFacade: PurchaseOrdersFacade,
    producersFacade: ProducersFacade
  ): Promise<PickListInfo> {
    const purchaseOrderProducers: (Producer & WithId)[] = [];
    const updatedProducers = [...allProducers];

    for (const sd of scheduledDelivery) {
      for (const po of sd.purchaseOrders) {
        let isUpdateNeeded: boolean = false;
        const updatedOrderItems: OrderItem[] = [];
        for (let oi of po.orderItems) {
          if (oi.product.resellingFor) {
            let foundProducer: Producer & WithId;

            foundProducer = updatedProducers.find((producer) => {
              return producer.id === oi.product?.producerId;
            });

            if (!foundProducer) {
              isUpdateNeeded = true;
              foundProducer = await updateProducer(
                updatedProducers,
                oi,
                producersFacade
              );

              oi = {
                ...oi,
                product: {
                  ...oi.product,
                  producerId: foundProducer.id,
                },
              };
            }

            if (shouldAddProducer(oi, purchaseOrderProducers)) {
              purchaseOrderProducers.push(foundProducer);

              if (isUpdateNeeded) updatedProducers.push(foundProducer);
            }
          }
          updatedOrderItems.push(oi);
        }

        if (isUpdateNeeded) {
          const updatedPo: PurchaseOrder & WithId = {
            ...po,
            orderItems: updatedOrderItems,
          };
          scheduledDelivery[scheduledDelivery.indexOf(sd)].purchaseOrders[
            sd.purchaseOrders.indexOf(po)
          ] = updatedPo;
          await purchaseOrderFacade.updateItem(updatedPo);
        }
      }
    }

    const pickListInfo: PickListInfo = {
      purchaseOrderProducers: purchaseOrderProducers,
      scheduledDeliveries: scheduledDelivery,
    };

    return pickListInfo;
  }

  export function generateProductString(
    orderItem: OrderItem,
    currentLanguage: Language
  ): string {
    return (
      Product.displayWithOrganicAndIsLocal(
        orderItem.product,
        currentLanguage,
        true
      ) +
      (orderItem.format.grade
        ? ` ${Format.displayAdditionalInfos(orderItem.format, currentLanguage, [
            'grade',
          ])}`
        : '')
    );
  }

  export function generateFormatString(
    orderItem: OrderItem,
    currentLanguage: Language
  ): string {
    return (
      Format.display(orderItem.format, currentLanguage) +
      (orderItem.format?.adjustedPrice ? '*' : '')
    );
  }

  export function generateProductAndFormatString(
    orderItem: OrderItem,
    currentLanguage: Language
  ): string {
    return `${ScheduledDeliveriesUtils.generateProductString(
      orderItem,
      currentLanguage
    )} - ${ScheduledDeliveriesUtils.generateFormatString(
      orderItem,
      currentLanguage
    )}`;
  }

  export function groupOrderItemsByProducer(
    purchaseOrders: PurchaseOrder[],
    organizationName: string
  ): OrderItemsByProducer[] {
    if (!purchaseOrders) return [];

    const orderItemsWithCustomers =
      createOrderItemsWithCustomers(purchaseOrders);
    return createOrderItemsByProducers(
      orderItemsWithCustomers,
      organizationName
    );
  }

  export function createOrderItemsByProducerIds(
    orderItemsWithCustomerLines: (OrderItem & WithCustomerLines)[],
    organizationName: string
  ): _.Dictionary<OrderItemsByProducer> {
    if (!orderItemsWithCustomerLines.length) return {};

    const orderedOrderItemsWithCustomerLines = orderOrderItemsWithCustomerLines(
      orderItemsWithCustomerLines
    );

    const listOrderItemsByProducerIds = groupOrderItemsByProducerIds(
      orderedOrderItemsWithCustomerLines,
      organizationName
    );

    const sortedOrderItemsByProducer = sortOrderItemsByProducer(
      listOrderItemsByProducerIds
    );

    // Convert into a dictionary
    const dictionaryOrderItemsByProducerIds: {
      [producerIdWithName: string]: OrderItemsByProducer;
    } = {};

    sortedOrderItemsByProducer.map((orderItemsByProducer) => {
      /**
       * If there's no producerId it won't be displayed in `generateProducersSalesReport`
       * but will be displayed in `generateDeliveryReportPDF` and `generateScheduledDeliveriesPDF`
       */
      const uniqKey =
        orderItemsByProducer.orderItemsByCategory[0]?.orderItems[0]?.product
          .producerId || orderItemsByProducer.producerName;

      dictionaryOrderItemsByProducerIds[uniqKey] = orderItemsByProducer;
    });

    return dictionaryOrderItemsByProducerIds;
  }

  export function filterOrderItemsWithCustomersByProducer(
    orderItemsWithCustomers: (OrderItem & WithCustomer)[],
    producerIds: string[]
  ): (OrderItem & WithCustomer)[] {
    return orderItemsWithCustomers.filter((orderItemsWithCustomer) =>
      producerIds.includes(orderItemsWithCustomer.product.producerId)
    );
  }

  export function createOrderItemsWithCustomers(
    purchaseOrders: PurchaseOrder[]
  ): (OrderItem & WithCustomer)[] {
    if (!purchaseOrders) return [];
    return purchaseOrders.flatMap((purchaseOrder) =>
      purchaseOrder.orderItems.map((orderItem) => {
        return {
          ...orderItem,
          customer: getCustomerWithId(purchaseOrder.customer),
        };
      })
    );
  }

  function getCustomerWithId(
    customer: OrganizationSummary & {
      organizationId?: string;
    }
  ): OrganizationSummary & WithId {
    const customerWithId = {
      ...customer,
      id: customer.organizationId ?? '',
    };
    delete customerWithId.organizationId;
    return customerWithId;
  }

  function groupOrderItemsByProducerIds(
    orderItemsWithCustomers: (OrderItem & WithCustomerLines)[],
    organizationName: string
  ): OrderItemsByProducer[] {
    return _(orderItemsWithCustomers)
      .groupBy('product.producerId')
      .map((orderItemsWithCustomers) => {
        const producerName =
          orderItemsWithCustomers[0]?.product.resellingFor ?? organizationName;

        const orderItemsByCategory = groupOrderItemsByCategory(
          orderItemsWithCustomers
        );
        return {
          producerName,
          orderItemsByCategory: LangUtils.normalizedSortBy(
            orderItemsByCategory,
            (category) => category.categoryName
          ),
        };
      })
      .value();
  }

  function createOrderItemsByProducers(
    orderItemsWithCustomers: (OrderItem & WithCustomer)[],
    organizationName: string
  ): OrderItemsByProducer[] {
    const OrderItemsByProducer = _(orderItemsWithCustomers)
      .groupBy('product.producerId')
      .map((orderItemsWithCustomers) => {
        const producerName =
          orderItemsWithCustomers[0]?.product.resellingFor ?? organizationName;
        return {
          producerName,
          orderItemsByCategory: createOrderItemsByCategory(
            orderItemsWithCustomers
          ),
        };
      })
      .value();
    return sortOrderItemsByProducer(OrderItemsByProducer);
  }

  function groupOrderItemsByCategory(
    orderItemsWithCustomerLines: (OrderItem & WithCustomerLines)[]
  ): OrderItemsByCategory[] {
    return _(orderItemsWithCustomerLines)
      .groupBy('product.category.original')
      .map((orderItemsWithCustomerLines, categoryName) => {
        return {
          categoryName,
          orderItems: orderItemsWithCustomerLines,
        };
      })
      .value();
  }

  function createOrderItemsByCategory(
    orderItemsWithCustomers: (OrderItem & WithCustomer)[]
  ): OrderItemsByCategory[] {
    const orderItemsByCategory = _(orderItemsWithCustomers)
      .groupBy('product.category.original')
      .map((orderItemsWithCustomers, categoryName) => {
        return {
          categoryName,
          orderItems: createReducedOrderItemsWithCustomerLines(
            orderItemsWithCustomers
          ),
        };
      })
      .value();
    return LangUtils.normalizedSortBy(
      orderItemsByCategory,
      (category) => category.categoryName
    );
  }

  export function createOrderItemsWithCustomerLines(
    orderItemsWithCustomers: (OrderItem & WithCustomer)[]
  ): (OrderItem & WithCustomerLines)[] {
    return orderItemsWithCustomers.map((orderItemWithCustomer) => {
      return {
        ...orderItemWithCustomer,
        customerLines: [
          {
            customer: orderItemWithCustomer.customer,
            requestedQuantity: orderItemWithCustomer.requestedQuantity,
          },
        ],
      };
    });
  }

  function createReducedOrderItemsWithCustomerLines(
    orderItemsWithCustomers: (OrderItem & WithCustomer)[]
  ): (OrderItem & WithCustomerLines)[] {
    const orderItemsWithCustomerLines = _(orderItemsWithCustomers).reduce(
      (
        accumulator: (OrderItem & WithCustomerLines)[],
        orderItemWithCustomer: OrderItem & WithCustomer
      ) => {
        const orderItemWithCustomerLines = accumulator.find(
          (item) => item.format.id === orderItemWithCustomer.format.id
        );
        if (!orderItemWithCustomerLines) {
          return pushOrderItem(accumulator, orderItemWithCustomer);
        }
        updateOrderItem(orderItemWithCustomer, orderItemWithCustomerLines);
        return accumulator;
      },
      []
    );
    return orderOrderItemsWithCustomerLines(orderItemsWithCustomerLines);
  }

  function updateOrderItem(
    orderItemWithCustomer: OrderItem & WithCustomer,
    orderItemWithCustomerLines: OrderItem & WithCustomerLines
  ) {
    orderItemWithCustomerLines.requestedQuantity +=
      orderItemWithCustomer.requestedQuantity;
    const customerLine = orderItemWithCustomerLines.customerLines.find(
      (customerLine: CustomerLine) =>
        customerLine.customer.id === orderItemWithCustomer.customer.id
    );
    if (customerLine) {
      customerLine.requestedQuantity += orderItemWithCustomer.requestedQuantity;
    } else {
      orderItemWithCustomerLines.customerLines.push({
        customer: orderItemWithCustomer.customer,
        requestedQuantity: orderItemWithCustomer.requestedQuantity,
      });
    }
  }

  function pushOrderItem(
    accumulator: (OrderItem & WithCustomerLines)[],
    orderItemWithCustomer: OrderItem & WithCustomer
  ): (OrderItem & WithCustomerLines)[] {
    const orderItem = _.cloneDeep(orderItemWithCustomer);
    delete orderItem.customer;
    accumulator.push({
      ...orderItem,
      customerLines: [
        {
          customer: orderItemWithCustomer.customer,
          requestedQuantity: orderItemWithCustomer.requestedQuantity,
        },
      ],
    });
    return accumulator;
  }

  function orderOrderItemsWithCustomerLines(
    orderItemsWithCustomerLines: (OrderItem & WithCustomerLines)[]
  ): (OrderItem & WithCustomerLines)[] {
    return _(orderItemsWithCustomerLines)
      .orderBy(
        [
          (orderItemWithCustomerLines) =>
            LangUtils.normalizeString(
              orderItemWithCustomerLines.product.name.original
            ) + orderItemWithCustomerLines.product.id,
          (orderItemWithCustomerLines) =>
            orderItemWithCustomerLines.price.amount,
          (orderItemWithCustomerLines) =>
            LangUtils.normalizeString(
              orderItemWithCustomerLines.format.description?.original
            ),
        ],
        ['asc', 'asc', 'asc']
      )
      .map((orderItemWithCustomerLines) => ({
        ...orderItemWithCustomerLines,
        customerLines: orderCustomerLines(
          orderItemWithCustomerLines.customerLines
        ),
      }))
      .value();
  }

  function orderCustomerLines(customerLines: CustomerLine[]): CustomerLine[] {
    return LangUtils.normalizedSortBy(
      customerLines,
      (customerLine) => customerLine.customer.name
    );
  }

  function sortOrderItemsByProducer(
    orderItemsByProducers: OrderItemsByProducer[]
  ): OrderItemsByProducer[] {
    const orderedProducers = LangUtils.normalizedSortBy(
      orderItemsByProducers,
      (producer) => producer.producerName
    );
    // Put myself first
    return _.flatten(
      _.partition(
        orderedProducers,
        (oItems) =>
          !oItems.orderItemsByCategory[0]?.orderItems[0]?.product.producerId
      )
    );
  }

  export const privateScheduledDeliveriesUtils = {
    createOrderItemsWithCustomers,
    getCustomerWithId,
    createOrderItemsByProducers,
    groupOrderItemsByProducerIds,
    createOrderItemsByCategory,
    groupOrderItemsByCategory,
    createReducedOrderItemsWithCustomerLines,
    createOrderItemsWithCustomerLines,
    updateOrderItem,
    pushOrderItem,
    orderOrderItemsWithCustomerLines,
    orderCustomerLines,
    sortOrderItemsByProducer,
  };

  function getMessage(
    generalInstruction?: string,
    producerInstruction?: string,
    deliveryInstruction?: string
  ) {
    const message: string[] = [];

    if (generalInstruction && generalInstruction?.length)
      message.push(generalInstruction);
    if (producerInstruction && producerInstruction?.length)
      message.push(producerInstruction);
    if (deliveryInstruction && deliveryInstruction?.length)
      message.push(deliveryInstruction);

    return message;
  }

  export function extractDateFromRouteParams(params: Params): Date | null {
    const date = params['date'];
    if (!date) {
      return null;
    }
    return new Date(Number.parseInt(date));
  }

  export function buildDateRangeFromRouteParams(
    params: Params
  ): Interval | null {
    const date = extractDateFromRouteParams(params);
    if (!date) {
      return null;
    }
    return {
      start: startOfDay(date),
      end: endOfDay(date),
    };
  }
}
