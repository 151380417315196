<mat-form-field class="lang-selector mat-typography" subscriptSizing="dynamic">
  <mat-select
    (selectionChange)="changeLanguage($event)"
    [value]="currentLanguage$ | async"
    panelClass="langSelectorOverlayPanel"
  >
    <mat-option *ngFor="let language of allLanguages" [value]="language">
      {{ language }}
    </mat-option>
  </mat-select>
</mat-form-field>
